<template>
  <div>
    <div class="sticky-top">
      <div class="row">
        <center><img src="/images/luxury-portfolio.png" style="width: 150px;" /></center>      
        <form id="agentForm" class="formStyle" @submit.prevent="submitForm">
          <div class="col-md-12 ps-0">
            <h1 class="pb-2" style="color: #255699;">Send a message</h1>
            <center><p>Use the form below to send a message to each sales agent for this property.</p></center>
            <div class="banner-section agentForm pb-0 ps-0">
              <input type="text" class="form-control mb-2" v-model="data.name" placeholder="Name" id="name">
              <span class="error-message">{{ data.errors.name }}</span>
              <input type="tel" class="form-control mb-2" v-model="data.phone" placeholder="Phone" id="phone">
              <span class="error-message">{{ data.errors.phone }}</span>
              <input type="email" class="form-control mb-2" v-model="data.email" placeholder="Email" id="email">
              <span class="error-message">{{ data.errors.email }}</span>
            </div>
            <div class="col-md-12">
              <textarea class="form-control mb-2" v-model="data.message" placeholder="Your message" id="message"></textarea>
              <div class="col-md-12 pt-4">
                <div class="form-check mb-2">
                  <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                  <label class="form-check-label" for="acceptTerms"><small>I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></small></label>
                </div>
                <div class="form-check mb-2">
                  <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing">
                  <label class="form-check-label" for="receiveMarketing"><small>I want to receive marketing material</small></label>
                </div>
              </div>
              <center><button id="form-agent-property" type="submit" class="btn btn-primary mt-4" :disabled="!data.acceptTerms">SEND</button></center>
              <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
              <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
        },
      },
      successMessage: '',
      errorMessage: ''
    }
  },
  mounted() {
    // console.log("Mail details: ")
    // console.log(this.$route.params.propertyId)
    // console.log(this.$store.state.agentMails)
  },
  methods: {
    submitForm() {
      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = ''; 

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      if (!this.data.message) {
        this.data.errors.message = "Message is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/agent-contact-form.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          property: this.$route.params.propertyId,
          agents: this.$store.state.agentMails,
          receiveMarketing: this.data.receiveMarketing
        })
        .then(response => {
          // console.log(response.data)
          this.successMessage = "Email has been sent and an agent will be in contact with you soon.";
          // this.data.name = "";
          // this.data.email = "";
          // this.data.phone = "";
          // this.data.message = "";
          this.data.acceptTerms = false;
          this.data.receiveMarketing = false;
        })
        .catch(error => {
          this.errorMessage = "Email sending failed.";
        });
      }
    },
  },
}

</script>

